import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "license-generator",
    component: () => import("../views/LicenseGenerator.vue")
  }
];

const routerManager = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  linkActiveClass: "active", // active class for non-exact links.
  linkExactActiveClass: "active", // active class for *exact* links.
  routes
});


export default routerManager;
