import Vue from "vue";
import App from "./App.vue";
import routerManager from "@/router/routerManager";


Vue.config.productionTip = false;

const host = window.location.host;
const parts = host.split(".");

const router = () => {
  if (parts.includes(process.env.VUE_APP_MANAGER_URL)) {
    return routerManager;
  } else {
    return routerManager;
  }
};


new Vue({
  router: router(),
  render: h => h(App)
}).$mount("#app");
